import * as React from "react";
import { Page, Layout, DetailPageSlider } from "../../../components";
import { withStyles, Container, Grid, Box, Typography, Button, CircularProgress } from '@material-ui/core/';
import Rating from '@material-ui/lab/Rating';
import ProductAttributes from '../../../components/ProductAttributes'

const ProductDetails = (props) => {
	console.log('location', props.id)

	return (
		<Layout>
			<Page>
				<Container>
					{/* {props.id} */}
					<Box paddingY={10}>
						<Grid container spacing={2}>
							<Grid item md={6} lg={6} xs={12} sm={12}>
								<DetailPageSlider />
							</Grid>
							<Grid item md={6} lg={6} xs={12} sm={12}>
								<Typography variant='h4'>La Bohème Rose Gold</Typography>
								<Box height='16px' />
								<Box className={props.classes.priceSection}>
									<Box display='flex' alignItems='center'>
										<Box style={{ textDecoration: 'line-through' }}><Typography variant='h6'>$60.00 </Typography></Box>
										<Box width='16px' />
										<Box style={{ color: '#ec0101' }}><Typography variant='h6'>$40.00</Typography></Box>
									</Box>
									<Box>
										<Rating name="read-only" value={4} readOnly />
									</Box>
								</Box>
								<Box height='24px' />
								<Typography variant='body2'>
									Go sporty this summer with this vintage navy and white striped v-neck t-shirt from the Nike. Perfect for pairing with denim and white kicks for a stylish sporty vibe.
								</Typography>
								<Box>
									<ProductAttributes />
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Page>
		</Layout>
	)
}

const styles = (theme) => ({
	priceSection: {
		justifyContent: 'space-between',
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start'
		},
	}
})
export default withStyles(styles, { withTheme: true })(ProductDetails)
